@import 'styles/includes.folkhem.scss';

.RelatedProjects {
    &__Title {
        margin-bottom: 24px;
        line-height: 1.33;
        font-size: 2.4rem;
        line-height: 24px;
        color: rgba($colorBrandDark, 0.8);
        letter-spacing: 0.4px;

        @include media(md) {
            margin-bottom: 32px;
        }
    }

    &__Content {
        @include media(md) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -$gutter;
        }

        @include media(lg) {
            flex-wrap: nowrap;
        }
    }

    &__CardProject {
        margin-bottom: 40px;

        @include media(md) {
            margin-left: $gutter;
            width: calc(50% - #{$gutter});
        }

        @include media(lg) {
            width: 100%;
            margin-bottom: 0;
        }
    }

    &__Btn {
        text-align: center;
    }
}
