@import 'styles/includes.nordr.scss';

.RelatedProjects {
    &__Title {
        font-weight: 500;
        margin-bottom: 24px;
        font-size: 2.2rem;
        line-height: 1.33;
        letter-spacing: 0.8px;

        @include media(md) {
            font-size: 2.4rem;
        }
    }

    &__Content {
        @include media(md) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -$gutter;
        }

        @include media(lg) {
            flex-wrap: nowrap;
        }
    }

    &__CardProject {
        margin-bottom: 40px;


        @include media(md) {
            margin-left: $gutter;
            width: calc(50% - #{$gutter});
        }

        // If 3, put everything on the same line
        @include media(md) {
            .RelatedProjects--Size3 & {
                width: calc(33.3333% - #{$gutter});
            }
        }
    }

    &__Btn {
        text-align: center;
    }
}
